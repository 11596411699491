import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DepartmentsService} from '../../../master/modules/departments/departments.service';
import {InboxSystemUserService} from '../../../master/modules/inbox-system-user/inbox-system-user.service';
declare var $: any;
@Component({
  selector: 'app-edit-request-row',
  templateUrl: './edit-request-row.component.html',
  styles: []
})
export class EditRequestRowComponent implements OnInit {

  public routeCodesList = {'S': 'Purchase', 'I': 'Repair', 'O': 'Overhaul', 'E': 'Exchange'};
  public unitsList = {'10': 'EA', '12': 'FT', '76': 'GA', '3': 'IN', '5': 'LT'};
  public priorityList = {'routine': 'Routine', 'critical': 'Critical', 'aog': 'AOG'};


  requestRowForm: FormGroup = this.formBuilder.group({
    id: null,
    cat_code: ['', Validators.required],
    part_number: ['', Validators.required],
    description: ['', Validators.required],
    nsn_number: ['', Validators.required],
    unit: ['', Validators.required],
    tag_source: [''],
    quantity: ['', Validators.required],
    // customer_reference: ['', Validators.required],
    // due_date: ['', Validators.required],
    // delivery_date: ['', Validators.required],
    // end_application: ['', Validators.required],
    priority: ['', Validators.required]
  });

  public edit = false;
  public submitted = false;

  constructor(private formBuilder: FormBuilder,
              private inboxSystemUserService: InboxSystemUserService) {}

  ngOnInit() {
    this.inboxSystemUserService.editRequestRowModal.subscribe((response) => {
      this.inboxSystemUserService.loadRow(response.id).subscribe((requestRow) => {
        this.requestRowForm.patchValue(requestRow);
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.requestRowForm.invalid) {
      return;
    } else {

      const formData = Object.assign({}, this.requestRowForm.value);
      formData['unit_description'] = this.unitsList[formData.unit];
      // formData['route_description'] = this.routeCodesList[formData.cat_code];
      formData['priority_description'] = this.priorityList[formData.priority];
      // formData['cat_code_description'] = this.routeCodesList[formData.cat_code];
        this.inboxSystemUserService.updateRequestRow(formData).subscribe((response) => {
          this.submitted = false;
        });

      this.inboxSystemUserService.reloadRequestRowMethod();
      $('#editRequestRow').foundation('close');
    }
  }

  get f() { return this.requestRowForm.controls; }

  public resetForm() {
    this.requestRowForm = this.formBuilder.group({
      id: null,
      part_number: ['', Validators.required],
      description: ['', Validators.required],
      nsn_number: ['', Validators.required],
      unit: ['', Validators.required],
      tag_source: [''],
      quantity: ['', Validators.required],
      // customer_reference: ['', Validators.required],
      // due_date: ['', Validators.required],
      // delivery_date: ['', Validators.required],
      // end_application: ['', Validators.required],
      priority: ['', Validators.required]
    });
  }


}
