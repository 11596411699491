import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ((request.url.indexOf('assets') > 0)) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    const token = localStorage.getItem('Token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.info('HttpResponse::event =', event, ';');
          } else {
            // console.info('event =', event, ';')
          }
          return event;
        }
      ),
      catchError(
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.error._meta.status_code === 401 || err.status === 417 || err.error._meta.status_code === 417) {
              if (err.error && err.error._meta && err.error._meta.status_code === 401) {
                localStorage.removeItem('User');
                localStorage.removeItem('Token');
                localStorage.removeItem('Roles');
                localStorage.removeItem('Permissions');
                localStorage.removeItem('adminToken');
                this.router.navigate(['/login'], { replaceUrl: true });
              }

              if (err.error && err.error._meta && err.error._meta.status_code === 417) {
                localStorage.removeItem('User');
                localStorage.removeItem('Token');
                localStorage.removeItem('Roles');
                localStorage.removeItem('Permissions');
                localStorage.removeItem('adminToken');
                this.router.navigate(['/login'], { replaceUrl: true });
              }


            }

            if (err.status === 307 || err.error._meta.status_code === 307) {
              if (err.error && err.error._meta && err.error._meta.status_code === 307) {
                // localStorage.removeItem('User');
                // localStorage.removeItem('Token');
                // localStorage.removeItem('Roles');
                // localStorage.removeItem('Permissions');
                // localStorage.removeItem('adminToken');
                // this.router.navigate(['/login'], { replaceUrl: true });
              }
            }


            return throwError(err);
          }
        }
      )
    );




  }

}
