import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {LoginService} from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  isLoading = false;
  date = (new Date()).getFullYear();

  public wrongcredentials = false;
  public errormessage = '';

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('User') && localStorage.getItem('Token')) {
      const user = JSON.parse(localStorage.getItem('User'));
      if (user.type === 2) {
        this.router.navigate(['/portal/system-users/list'], { replaceUrl: true });
      } else {
        this.router.navigate(['/portal/orders/list'], { replaceUrl: true });
      }
    }

  }

  onSubmit() {
    const postData = Object.assign({}, this.loginForm.value);
    delete postData.remember;
    postData.grant_type = 'password';
    postData.client_id = '2';
    postData.client_secret = 'tp7gN47VBUHiNzMhjlGLP6YnKkeh1OsF1uJaPzxW';
    this.isLoading = true;
    this.loginService.signIn(postData)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (credentials: any) => {
          this.setUser();
        },
        (error: any) => {
          this.error = error;
          if (error.status === 401) {
            this.errormessage = error.error._meta.message;
            this.wrongcredentials = true;
          }
        }
      );
  }

  get f() { return this.loginForm.controls; }

  setUser() {
    this.loginService.setUser().subscribe((response) => {
      this.wrongcredentials = false;
      if (response.auth_user[0].type_id === 2 || response.auth_user[0].type_id === '2') {
        this.router.navigate(['/portal/users/list'], { replaceUrl: true });
      } else {
        this.router.navigate(['/portal/orders/list'], { replaceUrl: true });
      }
    },
      (error: any) => {
        if (error.status === 417) {
          this.errormessage = error.error._meta.message + ' Please contact your agent.';
          this.wrongcredentials = true;
        }
      });
  }

}
