import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {LoginLayoutComponent} from './login-layout.component';
import {ForgotpasswordComponent} from './forgotpassword/forgotpassword.component';
import {RegisterComponent} from './register/register.component';
import {OtpComponent} from './otp/otp.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotpasswordComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'otp',
        component: OtpComponent
      },
      {
        path: 'reset-password/:resettoken',
        component: ResetPasswordComponent
      },
      { path: '', redirectTo: 'login', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule {
}
