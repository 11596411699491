import {Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {DataService} from '../../../core/http/data.service';
import {LoadingService} from '../../../core/loading/loading.service';
import {ConfirmDialogService} from '../../../shared/services/confirm-dialog.service';
import {ToastrUtilsService} from '../../../shared/services/toastr.service';
import { EventEmitter } from '@angular/core';

@Injectable()
export class InboxSystemUserService extends DataService {

  @Output() editRequestRowModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadRequests: EventEmitter<any> = new EventEmitter<any>();


  endPointUrl = 'requests';
  endPointUrlRow = 'requestrows';
  endPointUrlNewOrder = 'neworders';
  endPointUrlNewOrderRow = 'neworderrows';

  endPointUrlApprovedFiat = 'approvedandfiats';
  constructor(http: HttpClient,
              private loading: LoadingService) {
    super(http);
  }

  // load the new requests made by the customer
  loadRequests(newold, search): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrl}?new=${newold}&search=${search}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // load request row for editing
  loadRow(id): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(id, this.endPointUrlRow)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // update the request row
  updateRequestRow(formData): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(formData, `${this.endPointUrlRow}/${formData.id}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // approve the request
  approve(id): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.UpdateWithoutBody(`${this.endPointUrl}/${id}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // ================================================================================
  // New order part
  // ================================================================================


  // load the new requests made by the customer
  loadNewOrders(newold, search): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrlNewOrder}?new=${newold}&search=${search}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // approve the order
  approveOrder(id): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.UpdateWithoutBody(`${this.endPointUrlNewOrder}/${id}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // ================================================================================
  // Data passing between componenets
  // ================================================================================

  getAllApprovedAndFiats(archived = 0): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrlApprovedFiat}?archived=${archived}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }
  archiveFiatToBeApproved(formData): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(formData, this.endPointUrlApprovedFiat)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }


  // ================================================================================
  // Data passing between componenets
  // ================================================================================

  editRequestRowModalMethod(id) {
    this.editRequestRowModal.emit({id: id});
  }

  reloadRequestRowMethod() {
    this.reloadRequests.emit();
  }

}
