import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../login.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  forgotForm: FormGroup;
  loading = false;
  error: string;
  resetsend = false;
  resetsendmessage = 'You request has been sent to NEDAERO. We will contact you asap';
  emailRegEx = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegEx)]]
    });
  }

  onSubmit() {
    if (this.forgotForm.valid) {
      const postData = Object.assign({}, this.forgotForm.value);
      this.loginService.sendForgotPassword(postData).subscribe((response) => {
        this.resetsend = true;
        setTimeout(() => {
          this.router.navigate(['/login'], { replaceUrl: true });
        }, 5000);

      });
    } else {
      this.toastr.error('Please fill in all the required fields');
    }
  }
}
