import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  private resettoken;
  private passwordmessage = 'Passwords do not match.';
  private passwordmessagelength = 'Password must be at least 6 chracters.';
  private password1;
  private password;
  private email;
  private password2;
  private passwordcheck;
  public resetPasswordComplete = false;
  public passwordsubmitted = false;
  public passwordnotsame = false;
  public passwordnotvalid = false;
  public registerComplete = false;
  constructor(private route: ActivatedRoute,
              private loginService: LoginService,
              private router: Router) { }

  ngOnInit() {
    this.resettoken = this.route.snapshot.paramMap.get('resettoken');
  }

  onSubmit() {
    this.passwordsubmitted = true;
    if (this.password1 !== this.password2) {
      this.passwordnotsame = true;
    }

    if (this.passwordsubmitted && (this.password1.length < 6 || this.password2.length < 6)) {
      this.passwordnotvalid = true;
    }

    const postData = {
      'email': this.email,
      'password': this.password1,
      'password_confirmation': this.password2,
      'token': this.resettoken,
    };
    this.loginService.sendResetPasswrd(postData, this.resettoken).subscribe((response) => {
      if (response._meta.status_code === 200) {
        this.router.navigate(['/login'], { replaceUrl: true });
      } else {
        this.passwordmessage = 'Something went wrong. Please contact your agent for more details.';
        this.passwordnotsame = true;
      }
    });
  }

  checkpasswords($event, p) {
    if (p === 1) {
      this.password1 = $event.target.value;
    } else {
      this.password2 = $event.target.value;
    }

    if (this.passwordsubmitted && (this.password1 !== this.password2)) {
      this.passwordnotsame = true;
    } else {
      this.passwordnotsame = false;
    }

    if (this.passwordsubmitted && (this.password1.length < 6 || this.password2.length < 6)) {
      this.passwordnotvalid = true;
    } else {
      this.passwordnotvalid = false;
    }
  }
}
