import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrUtilsService} from './services/toastr.service';
import {ConfirmDialogService} from './services/confirm-dialog.service';
import { EditSystemUserComponent } from './data-off-canvas/edit-system-user/edit-system-user.component';
import {RouterModule} from '@angular/router';
import { EditViewComponent } from './data-off-canvas/edit-view/edit-view.component';
import { EditRoleComponent } from './data-off-canvas/edit-role/edit-role.component';
import {EditDepartmentComponent} from './data-off-canvas/edit-department/edit-department.component';
import {EditRequestRowComponent} from './data-off-canvas/edit-request-row/edit-request-row.component';
import {UnderscorePipe} from './pipes/underscore.pipe';
import {SearchPartComponent} from './data-off-canvas/search-part/search-part.component';

@NgModule({
  declarations: [
    EditSystemUserComponent,
    EditSystemUserComponent,
    EditViewComponent,
    EditRoleComponent,
    EditDepartmentComponent,
    EditRequestRowComponent,
    SearchPartComponent,
    UnderscorePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule
  ],
  providers: [
    ToastrUtilsService,
    ConfirmDialogService
  ],
  exports: [
    EditSystemUserComponent,
    EditViewComponent,
    EditRoleComponent,
    EditDepartmentComponent,
    EditRequestRowComponent,
    SearchPartComponent,
    UnderscorePipe
  ]
})
export class SharedModule { }
