import {EventEmitter, Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {DataService} from '../../../core/http/data.service';
import {LoadingService} from '../../../core/loading/loading.service';
import {ConfirmDialogService} from '../../../shared/services/confirm-dialog.service';
import {ToastrUtilsService} from '../../../shared/services/toastr.service';

@Injectable()
export class PermissionGroupsService extends DataService {

  @Output() editPermissionGroupModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadPermissions: EventEmitter<any> = new EventEmitter<any>();

  endPointUrl = 'roles';
  constructor(http: HttpClient,
              private loading: LoadingService,
              private confirmDialogService: ConfirmDialogService,
              private router: Router,
              private toastr: ToastrUtilsService) {
    super(http);
  }

  loadPermissionGroups(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  createPermissionGroup(postData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(postData, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getPermissionGroup(PermissionGroupId: number): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(PermissionGroupId, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  updatePermissionName(updatedData: any, roleid: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(updatedData, `${this.endPointUrl}/${roleid}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  updatetPermissionGroups(updatedData: any, roleid: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(updatedData, `${this.endPointUrl}/${roleid}/permissions`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getPermissionsOfOnePermissionGroup(PermissionGroupId: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrl}/${PermissionGroupId}/permissions`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getAllEmptyPermissions(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetWithoutId(`permissions`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  // deletePermissionGroup(PermissionGroupId: number): Observable<any> {
  //   this.loading.setLoadingBarStatus(true);
  //   return this.Delete(PermissionGroupId, `${this.endPointUrl}`)
  //     .pipe(
  //       tap((response: any) => {
  //         this.toastr.warning('User deleted.');
  //         this.loading.setLoadingBarStatus(false);
  //         return response;
  //       }),
  //       catchError((val: any) => {
  //         this.loading.setLoadingBarStatus(false);
  //         return throwError(val);
  //       })
  //     );
  // }


  checkFormCancel(settings?: any): Observable<any> {
    return from(this.confirmDialogService.questionConfirmPopup(settings));
  }
  checkDeleteOperation(settings?: any): Observable<any> {
    return from(this.confirmDialogService.deleteConfirmPopup(settings));
  }
  redirectToPermissionGroupsMainPage() {
    this.router.navigate(['permission-groups'], { replaceUrl: true });
  }

  editPermissionGroupModalMethod(id, edit) {
    this.editPermissionGroupModal.emit({id: id, edit: edit});
  }

  reloadPermissionsMethod() {
    this.reloadPermissions.emit();
  }
}
