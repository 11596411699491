import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
const permissionsKey = 'Permissions';

@Injectable()
export class PermissionService {
  get portal_settings(): string {
    return this._portal_settings;
  }

  get user_departments(): string {
    return this._user_departments;
  }

  get roles(): string {
    return this._roles;
  }

  get departments(): string {
    return this._departments;
  }

  get views(): string {
    return this._views;
  }

  get general_settings(): string {
    return this._general_settings;
  }

  get orders(): string {
    return this._orders;
  }

  get inbox_lines(): string {
    return this._inbox_lines;
  }

  get tokens(): string {
    return this._tokens;
  }

  get system_users(): string {
    return this._system_users;
  }

  private _portal_settings = 'users';
  private _system_users = 'system-users';
  private _user_departments = 'users-departments';
  private _roles = 'roles';
  private _departments = 'departments';
  private _views = 'views';
  private _general_settings = 'generalsettings';
  private _orders = 'orders';
  private _inbox_lines = 'inbox-lines';
  private _tokens = 'tokens';




  private permissionList: any;

  constructor(private router: Router) { }

  isPermitted(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): boolean {
    if (this.checkIfTypeIsOne()) {
      return true;
    }
    if (!localStorage.getItem(permissionsKey)) {
      this.router.navigate(['login'], { replaceUrl: true });
    }
    this.permissionList = JSON.parse(localStorage.getItem(permissionsKey));
    for (const permissions of this.permissionList) {
      for (const permission of permissions) {
        permission.name = this.adjustName(permission.name);
        if ((state.url).includes(permission.name)) {
          // return permission.show;
          return true;
        }
      }

    }
    return true;
  }

  public checkIfHasPermision(module: any, action: any) {
    if (!localStorage.getItem(permissionsKey)) {
      return true;
    }
    this.permissionList = JSON.parse(localStorage.getItem(permissionsKey));
    for (const permissions of this.permissionList) {
      for (const permission of permissions) {
        if (module === permission.name) {
          return permission[action];
        }
      }
    }
    return true;
  }

  private adjustName(permissionname) {
    if (permissionname === this.system_users) { permissionname = 'system-users'; }
    if (permissionname === this.general_settings) { permissionname = 'portal-settings'; }
    return permissionname;
  }

  private checkIfTypeIsOne() {
    const type = JSON.parse(localStorage.getItem('User')).type;
    if (type === 1) {
      return true;
    }
    return false;
  }
}
