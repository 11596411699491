import { Component, OnInit } from '@angular/core';
import {ViewsService} from '../../../master/modules/views/views.service';
import {promise} from 'selenium-webdriver';
import Promise = promise.Promise;
import {delay} from 'rxjs/operators';
import {Observable, Subject, Subscriber} from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-edit-view',
  templateUrl: './edit-view.component.html',
  styles: []
})
export class EditViewComponent implements OnInit {
  public orderLineList: any[] = [];
  public quoteList: any[] = [];
  public salesOrderList: any[] = [];
  public invoiceList: any[] = [];
  public shipmentList: any[] = [];
  private postArray: { [id: string]: any; } = {};
  private formsArray: { [id: string]: any; } = {};
  public viewname = '';
  public viewId = '';
  public edit = false;
  public postData = [];
  constructor(private viewsService: ViewsService) { }

  ngOnInit() {
    this.viewsService.editViewModal.subscribe((response) => {
      this.edit = response.edit;
      if (this.edit) {
        this.getViews(response.id);
      } else {
        this.getEmptyViews();
        // this.quoteList = {entry_date: true, export: true, ordered_quantity: true, part_description: false, part_number: false, pdf: false, quantity_number: true, quoted_quantity: false, status: true};
        // this.salesOrderList = {back_order_quantity: true, delivery_date: true, export: true, order_number: false, pdf: false, sales_order_number: true, shipment_date: false, shipped_quantity: true};
        // this.invoiceList = {airway_bill: true, date: true, export: true, invoice_number: true, pdf: false, ship_via: true};
        // this.shipmentList = {date: true, export: true, pdf: false, shipment_number: true};
      }
    });
  }

  private getViews(id: any) {
    this.viewsService.getViewsOfOneView(id).subscribe((response) => {
      this.viewname = response.view[0].name;
      this.viewId = response.view[0].id;
      this.orderLineList = response.view[0].order_lines;
      this.quoteList = response.view[0].quotes;
      this.salesOrderList = response.view[0].sales_orders;
      this.invoiceList = response.view[0].invoices;
      this.shipmentList = response.view[0].shipments;
      delete this.orderLineList['id'];
      delete this.quoteList['id'];
      delete this.salesOrderList['id'];
      delete this.invoiceList['id'];
      delete this.shipmentList['id'];

    });
  }

  private getEmptyViews() {
    this.viewsService.getEmptyViews().subscribe((response) => {
      this.orderLineList = response.view_items[0];
      this.quoteList = response.view_items[1];
      this.salesOrderList = response.view_items[2];
      this.invoiceList = response.view_items[3];
      this.shipmentList = response.view_items[4];
    });
  }

  submitForm() {
    // initiate to be posted array with name FORMS
    this.postArray['forms'] = {};

    // Initiate quote array
    this.formsArray['1'] = {};

    // set the quote id inside the array of FORMS
    this.postArray['forms'] = this.formsArray;

    // loop through the quote list
    this.orderLineList['items'].forEach((ol) => {
      this.postArray['forms']['1'][ol.id] = {};
      this.postArray['forms']['1'][ol.id]['enabled'] = ol.value;
    });

    // Initiate quote array
    this.formsArray['2'] = {};

    // set the quote id inside the array of FORMS
    this.postArray['forms'] = this.formsArray;

    // loop through the quote list
    this.quoteList['items'].forEach((qq) => {
      this.postArray['forms']['2'][qq.id] = {};
      this.postArray['forms']['2'][qq.id]['enabled'] = qq.value;
    });
    // push the quote array to the postarray



    // Initiate sales array
    this.formsArray['3'] = {};

    // set the sales id inside the array of FORMS
    this.postArray['forms'] = this.formsArray;

    // loop through the salesorder list
    this.salesOrderList['items'].forEach((ss) => {
      this.postArray['forms']['3'][ss.id] = {};
      this.postArray['forms']['3'][ss.id]['enabled'] = ss.value;
    });



    // Initiate sales array
    this.formsArray['4'] = {};

    // set the sales id inside the array of FORMS
    this.postArray['forms'] = this.formsArray;

    // loop through the salesorder list
    this.invoiceList['items'].forEach((iv) => {
      this.postArray['forms']['4'][iv.id] = {};
      this.postArray['forms']['4'][iv.id]['enabled'] = iv.value;
    });


    // Initiate sales array
    this.formsArray['5'] = {};

    // set the sales id inside the array of FORMS
    this.postArray['forms'] = this.formsArray;

    // loop through the salesorder list
    this.shipmentList['items'].forEach((sp) => {
      this.postArray['forms']['5'][sp.id] = {};
      this.postArray['forms']['5'][sp.id]['enabled'] = sp.value;
    });


    this.postArray['name'] = this.viewname;
    this.postArray['id'] = this.viewId;
    if (this.edit) {
      setTimeout(() => {
        this.viewsService.updatetView(this.postArray).subscribe((response) => {
          this.viewsService.reloadViewMethod();
        });
        $('#editView').foundation('close');

      }, 1000);
    } else {
      delete this.postArray.id;
      setTimeout(() => {
          this.viewsService.addView(this.postArray).subscribe((response) => {
            this.viewsService.reloadViewMethod();
          });
        $('#editView').foundation('close');
      }, 1000);
    }


  }



  setView(checked, name, index, list) {
    this[list]['items'][index]['value'] = checked.target.checked;
  }


}
