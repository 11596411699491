import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingComponent} from './loading/loading.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AuthenticationService} from './authentication/authentication.service';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {HttpCacheService} from './http/http-cache.service';
import {ApiPrefixInterceptor} from './http/api-prefix.interceptor';
import {ErrorHandlerInterceptor} from './http/error-handler.interceptor';
import {CacheInterceptor} from './http/cache.interceptor';
import {DataService} from './http/data.service';
import {LoadingService} from './loading/loading.service';
import {HttpService} from './http/http-service';
import {RouteReusableStrategy} from './route-reusable-strategy';
import {Error} from 'tslint/lib/error';
import {PermissionGuard} from './permission/permission.guard';
import {PermissionService} from './permission/permission.service';
import {CommunicationService} from '../master/communication.service';

@NgModule({
  declarations: [LoadingComponent],
  exports: [LoadingComponent, HttpClientModule, TranslateModule, RouterModule],
  imports: [
    CommonModule
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    PermissionGuard,
    PermissionService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    DataService,
    LoadingService,
    CommunicationService,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
