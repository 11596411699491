import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class ConfirmDialogService {

  private _swal: any = swal;
  private deleteSettings: Object = {
    title: 'Are you sure that you want to delete this record?',
    text: `You will lose the record after this operation!`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel'
  };
  private createWithInputSettings: Object = {
    title: 'Are you sure you want to create a new record?',
    text: ``,
    type: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    input: '',
    inputPlaceholder: '',
    inputValue: '',
    inputValidator: (value: any) => {
      return !value && 'Field is required!';
    }
  };

  private createSettings: Object = {
    title: 'Are you sure you want to create a new record?',
    text: ``,
    type: 'success',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel'
  };

  private questionSettings: Object = {
    title: 'Are you sure that you want to close the form?',
    text: `You will be missing all the changes you have made!`,
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes Continue',
    cancelButtonText: 'Cancel',
  };
  constructor() { }
  /**
   *@todo example need to deleted
   */
  deleteConfirmPopup(settings: Object = {}) {
    return swal(Object.assign(settings, this.deleteSettings));
  }

  createConfirmPopup(settings: Object = {}) {
    return swal(Object.assign(settings, this.createSettings));
  }

  createConfirmInputPopup(settings: Object = {}) {
    return swal(Object.assign(settings, this.createWithInputSettings));
  }
  questionConfirmPopup(settings: Object = {}) {
    return swal(Object.assign(settings, this.questionSettings));
  }

}
