import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  public otpForm: FormGroup;
  public message = '';
  public showmessage = false;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otp_key: ['', [Validators.required]]
    });
  }

  onSubmit() {
    if (this.otpForm.valid) {
      const postData = Object.assign({}, this.otpForm.value);
      this.loginService.sendOtp(postData).subscribe((response) => {
        this.setUser();
      });
    } else {
      this.toastr.error('Please fill in all the required fields');
    }
  }

  private setUser() {
    this.loginService.setUser().subscribe((response) => {
      if (response.auth_user[0].type_id === 2 || response.auth_user[0].type_id === '2') {
        this.router.navigate(['/portal/system-users/list'], { replaceUrl: true });
      } else {
        this.router.navigate(['/portal/orders/list'], { replaceUrl: true });
      }
    });
  }

  resendOTP() {
    this.loginService.resendOtp().subscribe((response) => {
      if (response._meta.status_code === 200) {
        this.message = 'Your OTP has been resent to your email.';
        this.showmessage = true;
      } else {
        this.message = 'Something went wrong. Please contact your agent for more details.';
        this.showmessage = true;
      }
    });
  }
}
