import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
})
export class LoginLayoutComponent implements OnInit {
  date = (new Date()).getFullYear();
  constructor() { }

  ngOnInit(): void { }
}
