import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {DataService} from '../../../core/http/data.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../../core/loading/loading.service';

@Injectable()
export class RequestService extends DataService {

  endPointUrl = 'requests';
  endPointUrlParts = 'parts';
  endPointUrlAC = 'applicationcodes';
  endPointUrlPendingRFQs = 'requests?new=new&own=own';
  constructor(http: HttpClient,
              private loading: LoadingService) {
    super(http);
  }

  createRequest(postData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(postData, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }


  loadRequests(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  searchPart(query: string): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    // remove all white spaces
    query = query.replace(/\s/g, '');
    return this.GetWithoutId(`${this.endPointUrlParts}?search=${query}&type=quote`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getApplicationCodes(): Observable<any> {
    // this.loading.setLoadingBarStatus(true);
    return this.GetWithoutId(this.endPointUrlAC)
      .pipe(
        tap((response: any) => {
          // this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          // this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getPendingRFQs(): Observable<any> {
    // this.loading.setLoadingBarStatus(true);
    return this.GetWithoutId(this.endPointUrlPendingRFQs)
      .pipe(
        tap((response: any) => {
          // this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          // this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

}
