import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {DataService} from '../core/http/data.service';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class LoginService extends DataService {

  constructor(http: HttpClient,
              private router: Router,
              private authenticationService: AuthenticationService) {
    super(http);
  }

  signIn(postData: any): Observable<any> {
    return this.Create(postData, 'oauth/token')
      .pipe(
        tap((response: any) => {
          this.authenticationService.setTokenAndUser(response);
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }

  sendOtp(postData: any): Observable<any> {
    return this.Create(postData, 'otp')
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }
  resendOtp(): Observable<any> {
    return this.GetAll('otp')
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }

  setUser(): Observable<any> {
    return this.GetAll('auth').pipe(
      tap((response: any) => {
        this.authenticationService.setUser(response.auth_user[0]);
      }),
      catchError((val: any) => {
        if (val.status === 307) {
          this.router.navigate(['/otp'], { replaceUrl: true });
        }
        return throwError(val);
      })
    );
  }

  sendRegister(postData: any): Observable<any> {
    return this.Create(postData, 'register')
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }

  sendForgotPassword(postData: any): Observable<any> {
    return this.Create(postData, 'password/email')
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }

  sendResetPasswrd(postData: any, token: any): Observable<any> {
    return this.Create(postData, `password/reset/${token}`)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }
}



