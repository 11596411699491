import {EventEmitter, Injectable, Output} from '@angular/core';
import {DataService} from '../../../core/http/data.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../../core/loading/loading.service';
import {ConfirmDialogService} from '../../../shared/services/confirm-dialog.service';
import {ToastrUtilsService} from '../../../shared/services/toastr.service';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable()
export class ViewsService extends DataService {

  @Output() editViewModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadView: EventEmitter<any> = new EventEmitter<any>();

  endPointUrl = 'views';
  endPointUrlEmpty = 'views/1/items';

  constructor(private router: Router,
              private loading: LoadingService,
              private confirmDialogService: ConfirmDialogService,
              private toastr: ToastrUtilsService,
              http: HttpClient) {
    super(http);
  }

  loadViews(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getViewsOfOneView(viewId: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(viewId, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getEmptyViews(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetWithoutId(this.endPointUrlEmpty)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  addView (updatedData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(updatedData, `${this.endPointUrl}`)
      .pipe(
        tap((response: any) => {
          this.toastr.info('View updated successfully');
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  updatetView (updatedData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(updatedData, `${this.endPointUrl}/${updatedData.id}`)
      .pipe(
        tap((response: any) => {
          this.toastr.info('View updated successfully');
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  editViewModalMethod(id, edit) {
    this.editViewModal.emit({id: id, edit: edit});
  }

  reloadViewMethod() {
    this.reloadView.emit();
  }


}
