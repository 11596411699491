import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastrUtilsService {

  constructor(private toastr: ToastrService) {
  }
  success(message: string, title?: string) {
    this.clear();
    this.toastr.success(message, title);
  }
  info(message: string, title?: string) {
    this.clear();
    this.toastr.info(message, title);
  }

  warning(message: string, title?: string) {
    this.clear();
    this.toastr.warning(message, title);
  }
  error(message: string, title?: string) {
    this.clear();
    this.toastr.error(message, title);
  }
  private clear() {
    this.toastr.clear();
  }
}
