import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {

  loadingStateChange = new Subject();
  constructor() { }

  setLoadingBarStatus(status: boolean) {
    this.loadingStateChange.next(status);
  }

  getLoadingStatus(): Observable<any> {
    return this.loadingStateChange.asObservable();
  }
}
