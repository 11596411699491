import { Component, OnInit } from '@angular/core';
import {PermissionGroupsService} from '../../../master/modules/permission-groups/permission-groups.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

  public permissionsList: any[] = [];
  public selectedPermissionsList: any[] = [];
  public permissionGroupName;
  public permissionGroupId;

  public edit = false;
  public submitted = false;

  constructor(private permissionsGroupService: PermissionGroupsService) { }

  ngOnInit() {
    this.permissionsGroupService.editPermissionGroupModal.subscribe((response) => {
      if (response.edit) {
        this.getPermissions(response.id);
        this.edit = true;
      } else {
        this.permissionGroupName = '';
        this.getEmptyPermissions();
      }

    });
  }

  private getPermissions(id: any) {
    this.permissionsGroupService.getPermissionsOfOnePermissionGroup(id).subscribe((response) => {
      this.permissionGroupName = response.role_permissions[0].name;
      this.permissionGroupId = response.role_permissions[0].id;
      this.permissionsList = response.role_permissions[0].permissions;
    });
  }

  // get empty permissions and set them to be shown and saved
  private getEmptyPermissions() {
    this.permissionsGroupService.getAllEmptyPermissions().subscribe((response) => {
      const tmparray = []
      for (const permission of response.permissions) {
        const t = {
          id: permission.id,
          name: permission.name,
          show: false,
          store: false,
          update: false,
          delete: false
        };
        tmparray.push(t);
      }
      this.permissionsList = tmparray;
    });
  }

  onSubmit() {
    this.submitted = true;
    const permissionsSelected = {};
    for (const permission of this.permissionsList) {
      const tmpid = permission.id;
      const tmpobjd = { ['show']: permission.show , ['store'] : permission.store, ['update'] : permission.update, ['delete'] : permission.delete };
      permissionsSelected[tmpid] = tmpobjd;
    }
    const permissionName = {['name']: this.permissionGroupName};
    const permissions = {['permissions']: permissionsSelected};

    if (this.edit) {
      this.permissionsGroupService.updatePermissionName(permissionName, this.permissionGroupId).subscribe(() => {
      });
      this.permissionsGroupService.updatetPermissionGroups(permissions, this.permissionGroupId).subscribe(() => {
        this.permissionsGroupService.reloadPermissionsMethod();
        this.edit = false;
        $('#editRole').foundation('close');
      });
    } else {
      this.permissionsGroupService.createPermissionGroup(permissionName).subscribe((response) => {
        if (response.role) {
          this.savePermissions(permissions, response.role.id);
        }
      });
    }
  }

  private savePermissions(permissions: any, roleid) {
    this.permissionsGroupService.updatetPermissionGroups(permissions, roleid).subscribe(() => {
      this.permissionsGroupService.reloadPermissionsMethod();
      this.edit = false;
      $('#editRole').foundation('close');
    });
  }

  setPermissionAction(permissionItem, i) {
    this.permissionsList[i][permissionItem.target.value] = permissionItem.target.checked;
  }


}
