import {Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {DataService} from '../../../core/http/data.service';
import {LoadingService} from '../../../core/loading/loading.service';
import {ConfirmDialogService} from '../../../shared/services/confirm-dialog.service';
import {ToastrUtilsService} from '../../../shared/services/toastr.service';
import { EventEmitter } from '@angular/core';

@Injectable()
export class DepartmentsService extends DataService {

  @Output() editDepartmentModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadDepartment: EventEmitter<any> = new EventEmitter<any>();


  endPointUrl = 'departments';
  constructor(http: HttpClient,
              private loading: LoadingService,
              private confirmDialogService: ConfirmDialogService,
              private router: Router,
              private toastr: ToastrUtilsService) {
    super(http);
  }

  loadDepartments(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  createDepartment(postData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(postData, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.toastr.success('New department created successfully');
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getDepartment(departmentId: number): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(departmentId, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  updateDepartment(updatedData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(updatedData, `${this.endPointUrl}/${updatedData.id}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }




  deletetDepartment(departmentsId: number): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Delete(departmentsId, `${this.endPointUrl}`)
      .pipe(
        tap((response: any) => {
          this.toastr.warning('Department deleted.');
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  checkFormCancel(settings?: any): Observable<any> {
    return from(this.confirmDialogService.questionConfirmPopup(settings));
  }
  checkDeleteOperation(settings?: any): Observable<any> {
    return from(this.confirmDialogService.deleteConfirmPopup(settings));
  }
  redirectToSystemUsersMainPage() {
    this.router.navigate(['system-users'], { replaceUrl: true });
  }

  // ================================================================================
  // Data passing between componenets
  // ================================================================================

  editDepartmentModalMethod(id, name, edit) {
    this.editDepartmentModal.emit({id: id, name: name, edit: edit});
  }

  reloadDepartmentMethod() {
    this.reloadDepartment.emit();
  }

}
