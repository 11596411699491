import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DepartmentsService} from '../../../master/modules/departments/departments.service';
declare var $: any;
@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styles: []
})
export class EditDepartmentComponent implements OnInit {

  departmentForm: FormGroup = this.formBuilder.group({
    id: null,
    name: ['', [Validators.required]]
  });

  public edit = false;
  public submitted = false;

  constructor(private formBuilder: FormBuilder,
              private departmentService: DepartmentsService) {}

  ngOnInit() {
    this.departmentService.editDepartmentModal.subscribe((response) => {
      if (response.edit) {
        this.edit = true;
        const formData = {};
        formData['id'] = response.id;
        formData['name'] = response.name;
        this.departmentForm.patchValue(formData);
      } else {
        this.resetForm();
        this.edit = false;
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.departmentForm.invalid) {
      return;
    } else {

      const departmentFormData = Object.assign({}, this.departmentForm.value);
      if (this.edit) {
        delete departmentFormData.password;
        this.departmentService.updateDepartment(departmentFormData).subscribe((response) => {
          this.submitted = false;
        });
      } else {
        delete departmentFormData.id;
        this.departmentService.createDepartment(departmentFormData).subscribe((response) => {
          this.submitted = false;
        });
      }

      this.departmentService.reloadDepartmentMethod();
      $('#editDepartment').foundation('close');
    }
  }

  deleteDepartment(id: any){
    this.departmentService.deletetDepartment(id).subscribe((response) => {
      this.departmentService.reloadDepartmentMethod();
      $('#editDepartment').foundation('close');
    });
  }

  get f() { return this.departmentForm.controls; }

  public resetForm() {
    this.departmentForm = this.formBuilder.group({
      id: null,
      name: ['', [Validators.required]]
    });
  }


}
