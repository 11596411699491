import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DataService {
  private apiUrl = '';
  constructor(private httpClientService: HttpClient) {
    this.setApiUrl();
  }

  setApiUrl() {
    this.apiUrl = environment.serverUrl;
  }

  GetAll<T>(endPointUrl: string = ''): Observable<T> {
    return this.httpClientService.get<T>(this.apiUrl + endPointUrl);
  }

  Get<T>(Id: any, endPointUrl: string = '', paramUrl: string = ''): Observable<T> {
    return this.httpClientService.get<T>(this.apiUrl + endPointUrl + '/' + Id + paramUrl);
  }

  GetPdf(Id: any, endPointUrl: string = ''): Observable<any> {
    return this.httpClientService.get(this.apiUrl + endPointUrl + '/' + Id, {responseType: 'blob'});
  }

  GetWithoutId<T>(endPointUrl: string = ''): Observable<T> {
    return this.httpClientService.get<T>(this.apiUrl + endPointUrl);
  }




  GetWithParam<T>(Id: any, endPointUrl: string = '', paramUrl: string = ''): Observable<T> {
    return this.httpClientService.get<T>(this.apiUrl + endPointUrl + '/' + paramUrl + Id);
  }



  Create<T>(resource: any, endPointUrl: string = ''): Observable<T> {
    return this.httpClientService.post<T>(this.apiUrl + endPointUrl, JSON.stringify(resource));
  }


  Update<T>(resource: any, endPointUrl: string = ''): Observable<T> {
    return this.httpClientService.put<T>(this.apiUrl + endPointUrl, JSON.stringify(resource));
  }

  UpdateWithoutBody<T>(endPointUrl: string = ''): Observable<T> {
    return this.httpClientService.put<T>(this.apiUrl + endPointUrl, null);
  }

  Delete<T>(Id: any, endPointUrl: string = ''): Observable<any> {
    return this.httpClientService.delete<T>(this.apiUrl + endPointUrl + '/' + Id);
  }


}
