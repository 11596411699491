import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscore'
})
export class UnderscorePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value === null || value === undefined) {
      return value;
    }
    let newStr;
    newStr = value.replace(/-/g, ' ');
    newStr = newStr.replace(/_/g, ' ');
    newStr = newStr.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    if (newStr === 'Users Departments') {
      return 'Department users';
    }

    if (newStr === 'Generalsettings') {
      return 'General settings';
    }

    return newStr;
  }
}
