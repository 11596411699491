import { Injectable } from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from './permission.service';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {CommunicationService} from '../../master/communication.service';


@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private permissionService: PermissionService, private toastr: ToastrService, private communicationService: CommunicationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.permissionService.isPermitted(state, route)) {
      return true;
    }
    if (state.url === '/') {
      this.router.navigate(['/login'], { replaceUrl: true });
      return true;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.permissionService.isPermitted(state, route)) {
      return true;
    }
    if (state.url === '/') {
      this.router.navigate(['/login'], { replaceUrl: true });
      return true;
    }
    this.communicationService.sendShowPermissionError();
    return true;
  }
}
