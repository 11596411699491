import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoginService} from './login.service';
import {LoginComponent} from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import {LoginLayoutComponent} from './login-layout.component';
import { RegisterComponent } from './register/register.component';
import { OtpComponent } from './otp/otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, LoginRoutingModule],
  declarations: [LoginComponent, ForgotpasswordComponent, LoginLayoutComponent, RegisterComponent, OtpComponent, ResetPasswordComponent],
  providers: [LoginService]
})
export class LoginModule { }
