import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DepartmentsService} from '../../../master/modules/departments/departments.service';
import {RequestService} from '../../../master/modules/request/request.service';
declare var $: any;
@Component({
  selector: 'app-search-part',
  templateUrl: './search-part.component.html',
  styles: []
})
export class SearchPartComponent implements OnInit {
  searchPartForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder,
              private cd: ChangeDetectorRef,
              private requestService: RequestService) {}

  ngOnInit() {
    this.initiateForm();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.searchPartForm.invalid) {
      return;
    } else {
      const requestFormData = Object.assign({}, this.searchPartForm.value);
      this.requestService.searchPart(requestFormData.name).subscribe((response) => {
      });
    }
  }

  private initiateForm() {
    this.searchPartForm = this.formBuilder.group({
      name: '',
    });
  }

  get f() { return this.searchPartForm.controls; }


}
