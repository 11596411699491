import {Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {DataService} from '../../../core/http/data.service';
import {LoadingService} from '../../../core/loading/loading.service';
import {ConfirmDialogService} from '../../../shared/services/confirm-dialog.service';
import {ToastrUtilsService} from '../../../shared/services/toastr.service';
import { EventEmitter } from '@angular/core';

@Injectable()
export class SystemUsersService extends DataService {

  @Output() editSystemUserModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadSystemUsers: EventEmitter<any> = new EventEmitter<any>();


  endPointUrl = 'users';
  endPointUrlDepartments = 'departments';
  constructor(http: HttpClient,
              private loading: LoadingService,
              private confirmDialogService: ConfirmDialogService,
              private router: Router,
              private toastr: ToastrUtilsService) {
    super(http);
  }

  loadSystemUsers(orderby = '', az = '', role = '', department = '', search = '', page = '', paginate = ''): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrl}?type=2&orderby=${orderby}&az=${az}&role=${role}&department=${department}&search=${search}&page=${page}&paginate=${paginate}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  createSystemUser(postData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(postData, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.toastr.success('New system user created successfully');
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getSystemUser(systemUserId: number): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(systemUserId, this.endPointUrl)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  updatetSystemUser(updatedData: any): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(updatedData, `${this.endPointUrl}/${updatedData.id}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  loadAllDepartments(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(this.endPointUrlDepartments)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  sendForgotPassword(postData: any): Observable<any> {
    return this.Create(postData, 'password/email')
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((val: any) => {
          return throwError(val);
        })
      );
  }

  // deletetUser(userId: number): Observable<any> {
  //   this.loading.setLoadingBarStatus(true);
  //   return this.Delete(userId, `${this.endPointUrl}`)
  //     .pipe(
  //       tap((response: any) => {
  //         this.toastr.warning('User deleted.');
  //         this.loading.setLoadingBarStatus(false);
  //         return response;
  //       }),
  //       catchError((val: any) => {
  //         this.loading.setLoadingBarStatus(false);
  //         return throwError(val);
  //       })
  //     );
  // }
  //
  // searchUsers(input: string): Observable<any> {
  //   this.loading.setLoadingBarStatus(true);
  //   return this.GetAll(`${this.endPointUrl}?find=${input}`)
  //     .pipe(
  //       tap((response: any) => {
  //         this.loading.setLoadingBarStatus(false);
  //         return response;
  //       }),
  //       catchError((val: any) => {
  //         this.loading.setLoadingBarStatus(false);
  //         return throwError(val);
  //       })
  //     );
  // }

  checkFormCancel(settings?: any): Observable<any> {
    return from(this.confirmDialogService.questionConfirmPopup(settings));
  }
  checkDeleteOperation(settings?: any): Observable<any> {
    return from(this.confirmDialogService.deleteConfirmPopup(settings));
  }
  redirectToSystemUsersMainPage() {
    this.router.navigate(['system-users'], { replaceUrl: true });
  }

  // ================================================================================
  // Data passing between componenets
  // ================================================================================

  editSystemUserModalMethod(id, edit) {
    this.editSystemUserModal.emit({id: id, edit: edit});
  }

  reloadSystemUsersMethod() {
    this.reloadSystemUsers.emit();
  }

}
